.container-purchase
{
    background-color:#eaeff6;
    display: flex;
    margin:0 auto;
    justify-content: center;
    color: #415076;
    padding-top: 25px;
    padding-bottom: 100px;  
      font-family: "Inter", sans-serif !important;
      font-size: 17px;
}



.div-purchase {
    width: 30%;
    background-color: white !important;

}

.div-referal {
    width: 30%;
    background-color: white !important;
}

.title {
    justify-content: center;
    align-content: center;
    text-align: center;
    display:  block;
    margin:0 auto;
}

.hint-text
{
    padding-left: 20px;
}

.form-group{
    width: 100%;
    display: block !important;  
    justify-content: center;
    text-align: center;
    align-items: center;
}

.form-group div{
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: block;
}

.form-group div span {
    margin-left: -10px;
}
.btn {
    margin-top: 25px;
    border: none !important;
    background-image: linear-gradient(to right, #fdc113  0%, #ff8d00 100%);
    font-size: 12px !important;
    line-height: 24px ;
    text-transform: uppercase;
    height: 40px !important;
    width: 130px !important;
}
.btn:hover{
    background-image: linear-gradient(to right, #ff8d00 0%, #fdc113 100%) !important;
}

.form-control
{
    width: 90% !important;
    display: initial !important;
    border: 1px solid #ff8d00;
}
.form-control[readonly]
{
    background-color: white !important;
    color: blue;
}

.claim{
    width: 100%;
}
.claim button {
    width: 90% !important;
}

@media (min-width: 1000px) {   

    .div-purchase {
        width: 30%;
    }

.div-referal {
   width: 30%;
   margin-left: 30px;
 }

}





@media (max-width: 1360px) 
    {
        .hint-text
        {
            font-size: 16px !important;
        }
        .div-purchase 
    {
        width: 40%;
    }

    .div-referal {
        width: 40%;
    }
    }
    @media (max-width: 1000px) {   
    
        .container-purchase
        {
            display: grid;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    
        .div-purchase 
        {
            width: 100%;
            
        }
    
        .div-referal {
            width: 100%;
            margin-top: 10px;
        }
        .hint-text
        {
    
            font-size: 14px !important;
        }
        .container-sm{
            padding-top: 100px;
        }
    
    }
    